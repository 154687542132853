.git_description {
    margin-top: 20px;
    margin-left: 10%;
    width: 80%;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    border-radius: 20px;
    border: 1px solid #aaa;
    text-align: left;
}