html {
  height: 100%;
  width: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eee;
  height: 100%;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

hr {
  border: 0px solid red;
  border-top: 1px solid #eee;
}

/* button {
  background-color: #1c8adb;
  color: white;
  font-weight: bold;
  border: 0px solid black;
  width: 200px;
  height: 42px;
  margin-left: 10px;
  margin-right: 10px;
  padding: 10px;
  font-size: 1em;
  border-radius: 5px;
} */

input {
  padding: 10px;
  font-size: 1em;
  border-radius: 5px;
}

select {
  padding: 10px;
  font-size: 1em;
  border-radius: 5px;
}
