.device_card {
  width: 100%;
  height: 120px;
  border-radius: 20px;
  background-color: white;
}

.device_card_title {
  margin-top: 5px;
  margin-bottom: 5px
}

.device_card_divider {
  width: 80%;
}

.device_card_color_strip {
  width: 100%;
  height: 20px;
  border-radius: 20px 20px 0px 0px;
  background-color: lightgrey;
  opacity: 1;
}
