.attribute_advise {
  width: 70%;
  color: #aaa;
}

.add_attribute_button{
  width: 100px
}

.separator {
  width: 70%;
}

.no_attributes_message {
  margin-bottom: 20px;
  text-align: left;
  color: #aaa;
}